import React from "react";
import Footer from '../Footer';

export default function Contact_Us() {
    
    return (
        <>
            <video autoPlay loop muted className="fixed w-full h-full top-0 left-0 object-cover -z-[1] overflow-hidden">
                <source 
                    src={"https://mindsqaurestorage.blob.core.windows.net/website/assets/vids/contact_us_mountains.mp4"} 
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>

            <main className="flex flex-col h-screen justify-center items-center mx-auto sm:w-10/12 md:w-9/12 lg:mx-auto lg:flex-row lg:w-10/12 lg:gap-x-10 xl:w-9/12 2xl:w-8/12">
                <section className="text-white p-4 my-4 xl:relative xl:right-12">
                    <h1 className="text-3xl mb-4">Get in touch</h1>
                    <p className="opacity-80 mt-4">Get in touch to discuss business and partnership opportunities, press or careers.</p>
                </section>

                <div className="w-11/12 my-4 xl:relative xl:left-12 2xl:-mr-14 xl:max-w-[540px]">
                    <form className="bg-white opacity-80 text-gray-500 text-sm p-3 flex flex-col justify-center items-center self-start max-h-[600px] min-h-[500px]">
                        <select className="w-11/12 bg-white mt-3 pb-3 border-0 border-b border-solid border-gray-300 outline-none mb-5">
                            <option value="">WHAT CAN WE HELP YOU WITH?</option>
                            <option value="">BECOMING A CLIENT</option>
                            <option value="">INVESTMENT</option>
                            <option value="">PARTNERSHIP</option>
                            <option value="">PRESS INQUIRY</option> 
                        </select>

                        <label className='block mt-3  self-start relative left-4 sm:left-6 lg:left-5 2xl:left-7' htmlFor="name">NAME*</label>
                        <input className='w-11/12 border-0 border-b border-solid border-gray-300 outline-none mb-5' type="text" name='name' />

                        <label className='block mt-3  self-start relative left-4 sm:left-6 lg:left-5 2xl:left-7' htmlFor="email">EMAIL*</label>
                        <input className='w-11/12 border-0 border-b border-solid border-gray-300 outline-none mb-5' type="text" name='email' />
                        
                        <label className='block mt-3  self-start relative left-4 sm:left-6 lg:left-5 2xl:left-7' htmlFor="phone">PHONE</label>
                        <input className='w-11/12 border-0 border-b border-solid border-gray-300 outline-none mb-5' type="text" name='phone' />
                        
                        <label htmlFor="form-message" className='block mt-3  self-start relative left-4 sm:left-6 lg:left-5 2xl:left-7'>MESSAGE*</label>
                        <textarea name="form-message" className='w-11/12 border-0 border-b border-solid border-gray-300 outline-none mb-5 min-h-[80px]' cols="30" rows="5"></textarea>
                            
                        <button className='block self-start mt-3 text-black relative left-4 mb-5 sm:left-6 lg:left-5 2xl:left-7' type="submit">SUBMIT</button>
                    </form>
                </div>

            </main>

            <footer>
                <Footer />
            </footer>
            
        </>
    )
}