import React, { useState, useEffect } from "react";
import logo from "../assets/img/new-logo.png";
import SlideoverMenu from "./SlideoverMenu";
import { Link } from "react-router-dom";
import { Line } from "rc-progress";

export default function Header() {
  const [scrolledToWhiteBg, setScrolledToWhiteBg] = useState(false);
  const [hamburgerColor, setHamburgerColor] = useState("#ffffff");
  const [activeVideo, setActiveVideo] = useState(1);
  const [progressA, setProgressA] = useState(0);
  const [progressB, setProgressB] = useState(0);
  const [progressC, setProgressC] = useState(0);

  const incrementProgress = (prevProgress) => {
    const newProgress = prevProgress + 1;
    return newProgress === 100 ? 0 : newProgress;
  };

  useEffect(() => {
    const intervalIds = [null, null, null];

    const incrementTheProgress = () => {
      const progressSetters = [setProgressA, setProgressB, setProgressC];
      const activeIndex = activeVideo - 1;

      intervalIds.forEach((intervalId, index) => {
        if (index === activeIndex) {
          intervalIds[index] = setInterval(() => {
            progressSetters[index]((prevProgress) => incrementProgress(prevProgress));
          }, 50); // Adjust the interval for smoother transitions
        } else {
          clearInterval(intervalId);
          intervalIds[index] = null;
          progressSetters[index](0); // Reset other progress bars to 0
        }
      });
    };

    incrementTheProgress();

    // Clear the intervals when the component is unmounted
    return () => {
      intervalIds.forEach((intervalId) => clearInterval(intervalId));
    };
  }, [activeVideo]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveVideo((prevActive) => (prevActive % 3) + 1);
    }, 5000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = window.scrollY;
      setScrolledToWhiteBg(scrollHeight > window.innerHeight - 64);
      if (scrollHeight >= window.innerHeight - 64) setHamburgerColor("#000000");
      else setHamburgerColor("#ffffff");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={"w-full bg-transparent h-20 fixed z-40"}>
      <div className={"container mx-auto flex justify-between items-center"}>
        <div className={"h-20 sm:h-20 p-4"}>
          <Link to="/">
            <img
              className={`h-full ${
                scrolledToWhiteBg ? "brightness-0" : "brightness-100"
              }`}
              src={logo}
              alt=""
            />
          </Link>
        </div>
        <div
          className={`flex ${
            scrolledToWhiteBg ? "text-black" : "text-white"
          } items-center justify-center p-4`}
        >
          <div className={"hidden sm:flex mr-6"}>
            <Link
              to="/what-we-do"
              className={"py-1 hover:border-indigo-500 mx-2"}
            >
              WHAT WE DO{" "}
              <Line percent={progressA} strokeWidth={2} strokeColor="#ffffff" />
            </Link>
            <Link
              to="/how-we-do-it"
              className={"py-1 hover:border-indigo-500 mx-2"}
            >
              HOW WE DO IT{" "}
              <Line percent={progressB} strokeWidth={2} strokeColor="#ffffff" />
            </Link>
            <Link
              to="/what-it-delivers"
              className={"py-1 hover:border-indigo-500 mx-2"}
            >
              WHAT IT DELIVERS{" "}
              <Line percent={progressC} strokeWidth={2} strokeColor="#ffffff" />
            </Link>
          </div>
          <SlideoverMenu theme={hamburgerColor} />
        </div>
      </div>
    </div>
  );
}
