import React, { useEffect } from "react";
import ContentBanner from "../HowWeDoItBanner";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import BouncingArrow from "../utilities/BouncingArrow";
import ScrollEffect from "../utilities/ScrollEffect";
import LoadingCircle from "../utilities/LoadingCircle";

export default function HowIt() {
  return (
    <div>
      <ScrollEffect />
      <div
        className={
          "w-full h-screen bg-cover bg-center flex items-end animate-zoom-in relative -z-10" // Change to 'bg-center'
        }
        style={{
          backgroundImage: `url(${"https://mindsqaurestorage.blob.core.windows.net/website/assets/img/how-we-do.jpg"})`,
        }}
      >
        {/* <BouncingArrow /> */}
        <LoadingCircle />
        <div className="absolute z-10 top-2/3 w-full">
          <div className="max-w-screen-2xl mx-auto text-white">
            <Link to={"/"}>
              <span className="border-b sm:ml-16 xl:ml-24">Home</span>
            </Link>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-semibold max-w-screen-sm my-2 sm:mx-16 xl:mx-24">
              Content humans, better decisions
            </h1>
          </div>
        </div>
      </div>
      <ContentBanner />
      {/* <div className={"h-full"}>
        <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4"></div>
      </div> */}
      <Footer dark={true} />
    </div>
  );
}
