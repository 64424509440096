import { useEffect } from "react"

export default function ScrollEffect() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant"
    })    
    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth'
          })
        }, 5000)
        return () => clearTimeout(scrollTimeout);
      },[])
}