import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

export default function SlideoverMenu({theme}) {
    const [open, setOpen] = useState(false);
    

    return (
        <div>
            <div className={'cursor-pointer'} onClick={() => setOpen(true)}>
                <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 48 48"
        >
          <title>menu</title>
          <g fill={theme}>
            <path d="M45,22H3c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1h42c0.552,0,1-0.448,1-1v-2 C46,22.448,45.552,22,45,22z"></path>
            <path
              fill={theme}
              d="M23,36H3c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1h20c0.552,0,1-0.448,1-1v-2C24,36.448,23.552,36,23,36z "
            ></path>
            <path
              fill={theme}
              d="M45,8H25c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1h20c0.552,0,1-0.448,1-1V9C46,8.448,45.552,8,45,8z"
            ></path>
          </g>
        </svg>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 overflow-hidden z-50"
                    onClose={() => setOpen(false)}
                >
                    <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            {/* This is the grayed background overlay */}
                            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="fixed inset-y-0 right-0 max-w-full flex">
                                <div className="w-screen max-w-md">
                                    {/* This is your panel */}
                                    <Dialog.Panel className="h-screen flex flex-col bg-white shadow-xl">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                            <div className="px-4 sm:px-6">
                                              <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">
                                                  {" "}
                                                  MINDSQUARE{" "}
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                  <button
                                                    type="button"
                                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                                    onClick={() => setOpen(false)}
                                                  >
                                                    <span className="sr-only">Close panel</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                              {/* Replace with your content */}
                                              <div className="absolute inset-0 px-4 sm:px-6">
                                                <div
                                                  className={
                                                    "flex flex-col justify-center items-center p-5 h-full"
                                                  }
                                                >
                                                  <div>
                                                    <div className="max-w-6xl mx-auto my-auto flex gap-7 flex-wrap justify-center text-gray-800 font-light text-2xl sm:text-4xl">
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"/what-we-do"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            What We Do
                                                          </span>
                                                        </Link>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"how-we-do-it"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            How We Do It
                                                          </span>
                                                        </Link>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"/what-it-delivers"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            What It Delivers
                                                          </span>
                                                        </Link>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"/partners"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            Partners
                                                          </span>
                                                        </Link>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"/our-team"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            Our Team
                                                          </span>
                                                        </Link>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"/newsroom"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            Newsroom
                                                          </span>
                                                        </Link>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"/contact"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            Contact
                                                          </span>
                                                        </Link>
                                                      </div>
                                                    </div>
                                                    <div className="max-w-6xl mx-auto my-auto flex gap-7 flex-wrap justify-around text-gray-500 mt-4 sm:text-2xl">
                                                      <div className={"min-w-fit"}>
                                                        <Link to={"/careers"}>
                                                          <span
                                                            onClick={() => {
                                                              setOpen(false);
                                                            }}
                                                          >
                                                            {" "}
                                                            Careers
                                                          </span>
                                                        </Link>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <a href={"www.facebook.com"}>Facebook</a>
                                                      </div>
                                                      <div className={"min-w-fit"}>
                                                        <a href={"www.linkedin.com"}>Linkedin</a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* /End replace */}
                                            </div>
                                          </div>
                                    </Dialog.Panel>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
