import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Homee from "./components/Homee";
import WhatWe from "./components/pages/WhatWe";
import WhatIt from "./components/pages/WhatIt";
import HowIt from "./components/pages/HowIt";
import Careers from "./components/pages/Careers";
import About from "./components/pages/About";
import Contact_Us from "./components/pages/Contact_Us";
import CustomCursor from "./components/utilities/CustomCursor";
import Header from "./components/Header";
import NotFound from "./components/NotFound";
import Partners from "./components/pages/Partners";
import OurTeam from "./components/pages/OurTeam";
import Newsroom from "./components/pages/Newsroom";

function App() {
    return (
        <Router basename={'/'} >
        <section className={"h-full relative"}>
            <CustomCursor/>
            <Header/>
            <Routes>
                <Route path={"/"} element={<Homee/>}/>
                <Route path={"/what-we-do"} element={<WhatWe/>}/>
                <Route path={"/how-we-do-it"} element={<HowIt/>}/>
                <Route path={"/what-it-delivers"} element={<WhatIt/>}/>
                <Route path={"/careers"} element={<Careers/>}/>
                <Route path={"/about-us"} element={<About/>}/>
                <Route path={"/partners"} element={<Partners/>}/>
                <Route path={"/contact"} element={<Contact_Us/>}/>
                <Route path={"/our-team"} element={<OurTeam/>}/>
                <Route path={"/newsroom"} element={<Newsroom/>}/>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </section>
        </Router>
    );
}

export default App;
