import React, {Component, Fragment} from "react";
import logo from '../assets/img/logo.jfif';
import {Transition} from "@headlessui/react";

class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {render: true};
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({render: false})
        }, 2000)
    }

    render() {
        return (
            <Transition.Root show={this.state.render} as={Fragment}>
                <div className={'w-full z-50 fixed h-full'}>
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-1000 "
                        leaveFrom="-translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div
                            className={'w-full h-full flex justify-center items-center bg-white'}>
                            <img className={'h-24 animate-pulse '} src={logo} alt="logo"/>
                        </div>
                    </Transition.Child>
                </div>
            </Transition.Root>
        )
    }
}
export default Landing;