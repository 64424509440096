import React from "react";

export default function ProfileCard() {
    return (
        <div className="flex flex-col items-center mb-10 sm:flex-row sm:mb-16 md:flex-col lg:mx-5 xl:flex-row">
            <div className="w-10/12 sm:w-6/12 sm:ml-10 sm:mr-7 md:w-9/12 xl:w-5/12">
                <img src="https://mindsqaurestorage.blob.core.windows.net/website/assets/img/profile-photo.jpg"></img>
            </div>
            <div className="flex flex-col items-center">
                <p className="mt-2 mb-1 text-lg text-gray-800">Name</p>
                <p className="italic mt-1 mb-6 text-gray-800">Designation</p>
            </div>
        </div>
    )
}