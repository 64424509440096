// AccordionItem.js
import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import DOMPurify from "dompurify";
import { Transition } from "@headlessui/react";

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const accordionAns = DOMPurify.sanitize(answer);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <div
        className="flex cursor-pointer my-7 justify-between border-b py-4 items-center sm:my-10"
        onClick={toggleAccordion}
      >
        <div className="text-lg font-semibold">{question}</div>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </div>

      <Transition
        show={isOpen}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={`text-gray-500`}
          dangerouslySetInnerHTML={{ __html: accordionAns }}
        ></div>
      </Transition>
    </div>
  );
};

export default AccordionItem;
