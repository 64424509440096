import WhatWeDo from "../../assets/img/what-we-do.jpg";
import down from "../../assets/img/svg/down.svg";
import {Link} from "react-router-dom";
import Footer from "../Footer";

export default function About() {
    return(
        <div>

            <div className={"w-full h-screen bg-cover flex items-end"} style={{backgroundImage: `url(${WhatWeDo})`}}>
                <div className={"mx-auto max-w-2xl"}><img className={"h-20 animate-bounce"} src={down}
                                                          alt=""/></div>
                <div className={"absolute z-10 top-2/3 w-full"}>
                    <div className={"max-w-screen-2xl mx-auto text-white"}>
                        <Link to={'/'} ><span className={"border-b"}>Home</span></Link>
                        <div className={"text-6xl max-w-screen-sm"}>We make businesses grow faster</div>
                    </div>
                </div>
            </div>
            <div className={"h-full"}>
                <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
                </div>
            </div>
            <Footer dark={true}/>
        </div>
    )
}