import React from "react";
import {Loader2} from 'lucide-react'
import { MoveDown } from 'lucide-react';


const BouncingArrow = () => {
    return(
        <div className="mx-auto relative top-5">
          <Loader2 color="#ffffff" size="48" strokeWidth="1" className="animate-one-spin"/> 
          <MoveDown color="#ffffff" size="23" strokeWidth="2" className="relative left-[12.5px] bottom-[35px] animate-bounce-3"/>
        </div>

        /* <svg
        
          viewBox="0 0 152.2 37.4"
          height={"46px"}
          width={"32px"}
          transform="rotate(90)"
        >
          <path
            fill={"white"}
            stroke={"white"}
            stroke-width="2"
            d="M129 0l-1.4 3.3 17.1 13.3H0v4.2h144.7l-17.1 13.3 1.4 3.3 23.2-18.7z"
          ></path>
        </svg> */
        
      )
}

export default BouncingArrow;