import React from "react";
import ProfileCard from "../utilities/ProfileCard";
import Footer from "../Footer";

export default function OurTeam() {
    return (
        <div className="bg-gray-300 h-full w-full overflow-x-hidden">
            
            <div className="relative top-32 md:top-40">
                    <header className="text-center mb-10">
                        <h1 className="text-4xl font-semibold text-gray-900 xl:text-5xl">Meet Our Team</h1>
                    </header>
                
                <section className="md:grid md:grid-cols-2 lg:mx-32">
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />
                        <ProfileCard />                        
                </section>
                
                <footer>
                    <Footer dark={true} />
                </footer>
            </div>

            
        </div>
    )
}