import React from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Footer from "./Footer";

export default function Intro1() {
  return (
      <div>
        <video
          autoPlay
          loop
          muted
          className="fixed w-full h-full top-0 left-0 object-cover -z-[1] overflow-hidden"
        >
          <source
            src={
              "https://mindsqaurestorage.blob.core.windows.net/website/assets/vids/intro1.mp4"
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <main className="h-screen flex flex-col justify-center text-white p-5 items-center">
          <div className="mb-0.5">
            <h1 className="text-xl sm:text-2xl md:text-3xl md:text-center lg:text-4xl">
              Our global work environment provides high performance and agile
              project execution
            </h1>
          </div>

          <div className="flex items-center mt-0.5">
            <Link to={"/what-we-do"} className={"pr-2 hover:pr-4"}>
              Find out what can be done
            </Link>
            <svg viewBox="0 0 152.2 37.4" height={"16px"} width={"32px"}>
              <path
                fill={"white"}
                d="M129 0l-1.4 3.3 17.1 13.3H0v4.2h144.7l-17.1 13.3 1.4 3.3 23.2-18.7z"
              ></path>
            </svg>
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
  );
}
