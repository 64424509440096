import React from "react";

const ContentBanner = () => {
  return (
    <div className="container max-w-7xl mx-auto bg-white my-20 px-16 md:px-20 lg:px-28">
      <h2 className="text-2xl font-semibold mb-4">
        Elevating Insights, Empowering Choices.
      </h2>
      <p className="text-gray-600 mb-6">
        At Mind Square, we believe in the synergy of shared wisdom. With our
        network of consultants, you're not just accessing knowledge – you're
        tapping into a collective intelligence that enriches your decisions. Our
        success aligns with yours, and we're committed to propelling you
        forward.
      </p>
      <p className="text-gray-600 mb-6">
        Navigating challenges becomes seamless. Our goal is to clear your path
        to success, minimizing hurdles and amplifying opportunities. We provide
        insightful perspectives that empower, and a robust digital
        infrastructure that strengthens.
      </p>
      <p className="text-gray-600 mb-6">
        Whether managing your digital presence or offering a dedicated
        consultant, our pledge remains steadfast. We're your allies in
        advancement, driven by mutual success and unwavering devotion.
      </p>
      <p className="text-gray-600">
        Together, we transform ideas into impactful choices. Join us at Mind
        Square, where we harness human insights to enhance your decisions, and
        together, we craft a future defined by boundless potential.
      </p>
    </div>
  );
};

export default ContentBanner;
