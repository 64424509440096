import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Intro1 from "./Intro1";
import Intro2 from "./Intro2";
import Intro3 from "./Intro3";
import Landing from "./Landing";
import "../styles/index.scss";

export default function Homee () {
  const [currentVideo, setCurrentVideo] = useState(1);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentVideo((prevVideo) => (prevVideo % 3) + 1);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <TransitionGroup>
      <Landing/>
      <CSSTransition
        key={currentVideo}
        timeout={1000} // Adjust the timeout based on your transition duration
        classNames="slide"
      >
        <div>
          {currentVideo === 1 && <Intro1 />}
          {currentVideo === 2 && <Intro2 />}
          {currentVideo === 3 && <Intro3 />}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}
