import React, {Component} from "react";

class CustomCursor extends Component {
    componentDidMount() {
        const cursor = document.querySelector('.cursor');
        document.addEventListener('mousemove', e => {
            cursor.setAttribute("style", "top: " + (e.pageY - 16) + "px; left: " + (e.pageX - 16) + "px")
        });
        document.addEventListener('click', () => {
            cursor.classList.add("expand");

            setTimeout(() => {
                cursor.classList.remove("expand");
            }, 500)
        })

    }

    render() {
        return (<div className={'hidden sm:block cursor'}>
        </div>);
    }
}

export default CustomCursor;