import React, { useState, useEffect } from 'react'
import { Circle } from 'rc-progress';
import { MoveDown } from 'lucide-react';

export default function LoadingCircle() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (progress < 100) {
        const interval = setInterval(() => {
          // Increment the count by 1
          setProgress((prevProgress) => prevProgress + 1);
          
          // If the count reaches 100, clear the interval
          if (progress === 100) {
            clearInterval(interval);
          }
        }, 40); // Adjust the interval (in milliseconds) for smoother transition
    
        // Clear the interval when the component is unmounted
        return () => clearInterval(interval);
        }
      }, [progress]);

    return (
        <div className='h-12 w-12 mx-auto my-2'>
            <Circle strokeWidth={5} strokeColor={"#f8fafc"} trailWidth={2} trailColor='white' percent={progress} strokeLinecap='round'/>
            <MoveDown color="#ffffff" size="23" strokeWidth="2" className="relative left-[12.5px] bottom-[35px] animate-bounce-3"/>
        </div>
  )
}
