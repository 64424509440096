import React from "react";

const PerkCard = ({ perk }) => {
  return (
    <div className="sm:flex">
      <div className="sm:flex-shrink-0">
        <div className="flow-root">
          <img className="w-28 h-24" src={perk.imageSrc} alt="" />
        </div>
      </div>
      <div className="mt-3 sm:mt-0 sm:ml-3">
        <h3 className="text-sm font-medium text-gray-900">{perk.name}</h3>
        <p className="mt-2 text-sm text-gray-500">{perk.description}</p>
      </div>
    </div>
  );
};

export default PerkCard;
