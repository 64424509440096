import React from "react";
import Career from "../../assets/img/careers.jpg";
import BouncingArrow from "../utilities/BouncingArrow";
import { Link } from "react-router-dom";
import Footer from "../Footer";

export default function Careers() {
  return (
    <div>
      <div
        className={"w-full h-screen bg-cover flex items-end"}
        style={{ backgroundImage: `url(${Career})` }}
      >
        <BouncingArrow />
        <div className={"absolute z-10 top-2/3 w-full"}>
          <div className={"max-w-screen-2xl mx-auto text-white ml-8"}>
            <Link to={"/"}>
              <span className={"border-b"}>Home</span>
            </Link>
            <div className={"text-6xl max-w-screen-sm"}>
              We make businesses grow faster
            </div>
          </div>
        </div>
      </div>
      <div className={"h-full"}>
        
      </div>
      <Footer dark={true} />
    </div>
  );
}
