import WhatItDeliver from "../../assets/img/what-it-deliver.jpg";
import BouncingArrow from "../utilities/BouncingArrow";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import ScrollEffect from "../utilities/ScrollEffect";
import LoadingCircle from "../utilities/LoadingCircle";

export default function WhatIt() {
  return (
    <div>
      <ScrollEffect />
      <div
        className={
          "w-full h-screen bg-cover bg-center flex items-end animate-zoom-in relative" // Change to 'bg-center'
        }
        style={{ backgroundImage: `url(${WhatItDeliver})` }}
      >
        {/* <BouncingArrow /> */}
        <LoadingCircle />
        <div className={"absolute z-10 top-2/3 w-full"}>
          <div className={"max-w-screen-2xl mx-auto text-white"}>
            <Link to={"/"}>
              <span className={"border-b sm:ml-16 xl:ml-24"}>Home</span>
            </Link>
            <div
              className={
                "text-4xl md:text-5xl lg:text-6xl font-semibold max-w-screen-sm my-2 sm:mx-16 xl:mx-24"
              }
            >
              Precise Satisfaction, Whatever the sector
            </div>
          </div>
        </div>
      </div>
      <div className={"h-full"}>
        <div className="max-w-7xl mx-auto lg:px-28 my-20 px-16 md:px-20">
          <h1 className="text-xl font-semibold mb-4">
            Rising from Challenges to Success: A Transformational Journey with
            MindSquare
          </h1>
          <p className="mb-6 text-gray-600">
            In the bustling world of business, challenges are inevitable, and
            opportunities often emerge from the depths of adversity. Such was
            the case for CompanyX, a mid-sized enterprise that found itself
            grappling with operational inefficiencies and struggling to keep
            pace with its industry's rapid evolution. Faced with these
            obstacles, the leaders of CompanyX turned to MindSquare for a
            transformative solution that would reshape their trajectory and
            empower them to grow faster.
          </p>
          <h1 className="text-xl font-semibold mb-4">
            Identifying the Hurdles: The Struggle for Growth
          </h1>
          <p className="mb-6 text-gray-600">
            CompanyX had a strong foundation and a dedicated team, but its
            technology infrastructure lagged behind its ambitious goals. The
            existing applications were struggling to handle the increasing user
            load, resulting in frustrating downtimes that affected both customer
            experience and internal operations. The need for scalability was
            evident, and this was where MindSquare's expertise came into play.
          </p>
          <h1 className="text-xl font-semibold mb-4">
            Crafting a Scalable Future: MindSquare's Solution
          </h1>
          <p className="mb-6 text-gray-600">
            Recognizing the critical role of technology in fueling growth,
            MindSquare embarked on a journey to revolutionize CompanyX's digital
            landscape. Drawing from their experience in building highly scalable
            applications, the MindSquare team collaborated closely with CompanyX
            to design and implement a robust solution that could handle peak
            user demands without compromising performance.
            <b />
            Leveraging the power of AI and machine learning, MindSquare
            introduced innovative features that not only optimized processes but
            also anticipated user behavior, enhancing the overall user
            experience. From predicting customer preferences to streamlining
            internal workflows, the infusion of AI breathed new life into
            CompanyX's operations.
          </p>
          <h1 className="text-xl font-semibold mb-4">
            Seamless Operations, Uninterrupted Growth
          </h1>
          <p className="mb-6 text-gray-600">
            The results were palpable. With MindSquare's scalable applications
            in place, CompanyX bid farewell to the days of downtime-induced
            frustration. Operations flowed seamlessly, allowing the CompanyX
            team to redirect their energy towards strategic initiatives rather
            than firefighting technical glitches. The newfound stability enabled
            CompanyX to focus on expanding its reach and exploring new avenues
            of growth.
          </p>
          <h1 className="text-xl font-semibold mb-4">
            Empowering Decisions through Data
          </h1>
          <p className="mb-6 text-gray-600">
            Beyond technology, MindSquare's commitment to data-driven insights
            was a game-changer for CompanyX. As part of their partnership,
            MindSquare analyzed the data generated by CompanyX's operations,
            providing invaluable insights that guided strategic decisions. With
            a clearer understanding of customer behavior, market trends, and
            operational efficiency, CompanyX's leadership gained the confidence
            to make impactful choices.
          </p>
          <h1 className="text-xl font-semibold mb-4">
            A Bright Future Defined
          </h1>
          <p className="mb-6 text-gray-600">
            The partnership between MindSquare and CompanyX is a testament to
            the transformative power of technology and innovation. What began as
            a struggle transformed into a story of resilience and growth.
            CompanyX's journey from operational challenges to scalable success
            showcases the impact of a well-crafted digital transformation
            strategy. <b />
            In the dynamic realm of business, where change is constant and
            growth is paramount, MindSquare proved to be the guiding force that
            helped CompanyX not only keep up with the times but define them.
            Through innovation, scalability, and a data-driven approach,
            MindSquare and CompanyX shaped a future that promises ongoing
            success and endless possibilities.
          </p>
        </div>
      </div>
      <Footer dark={true} />
    </div>
  );
}
