import WhatWeDo from "../../assets/img/what-we-do.jpg";
import BouncingArrow from "../utilities/BouncingArrow";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import PerkCard from "../utilities/PerkCard";
import ScrollEffect from "../utilities/ScrollEffect";
import LoadingCircle from "../utilities/LoadingCircle";

const perks = [
  {
    name: "Web development",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-delivery-light.svg",
    description:
      "We select the most relevant technology for your businesses and provide you with fast and cost effective product.",
  },
  {
    name: "Architecture",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-light.svg",
    description:
      "Keeping in mind your business logic, we provide with the\n" +
      "scalable and reliable technological architect. Get most whilst\n" +
      "spending less.\n",
  },
  {
    name: "AI/ML",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-returns-light.svg",
    description:
      "Integrating AI/ML algorithms provides you with ease of mind in many decision makings. No Human can predict faster than a computer",
  },
  {
    name: "Android/IOS",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg",
    description:
      "Advancement in technology requires constant improvements. We provide continuous improvements even after development. We all grow together.",
  },
  {
    name: "ERP integration",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg",
    description:
      "Your resources need to be looked after professionally. Integrating an ERP solution with your business needs helps you focus on other important tasks. Best help is constant supervision.",
  },
  {
    name: "UI/UX",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg",
    description:
      "First impression is the last impression. Let the professionals help you make that first impression right. Take control of human psychology.",
  },
  {
    name: "Support",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-planet-light.svg",
    description:
      "Humans make progress with their ability to cooperate. Once connected, we stay together through thick and thin.",
  },
];
export default function WhatWe() {
  return (
    <div>
      <ScrollEffect />
      <div
        className={
          "w-full h-screen bg-cover bg-center flex items-end animate-zoom-in relative"
        }
        style={{
          backgroundImage: `url(${WhatWeDo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflowX: "hidden",
        }}
      >
        {/* <BouncingArrow/> */}
        <LoadingCircle />
        <div className="absolute z-10 top-2/3 w-full">
          <div className="max-w-screen-2xl mx-auto text-white">
            <Link to={"/"}>
              <span className="border-b sm:ml-16 xl:ml-24">Home</span>
            </Link>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-semibold max-w-screen-sm my-2 sm:mx-16 xl:mx-24">
              We make businesses grow faster
            </h1>
          </div>
        </div>
      </div>
      <div className="h-full">
        <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
          <div className="max-w-2xl mx-auto px-16 sm:px-12 grid grid-cols-1 gap-y-12 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {perks.map((perk) => (
              <PerkCard key={perk.name} perk={perk} />
            ))}
          </div>
        </div>
      </div>
      <Footer dark={true} />
    </div>
  );
}
