import React from "react";
import Footer from "../Footer";
import ScrollEffect from "../utilities/ScrollEffect";
import AccordionItem from "../utilities/AccordionItem";
import LoadingCircle from "../utilities/LoadingCircle";


export default function Partners() {
    return (
        <>
            <main className="overflow-x-hidden">
                <ScrollEffect />
                <div className="bg-cover bg-center h-screen w-full" style={{backgroundImage: `url(${"https://mindsqaurestorage.blob.core.windows.net/website/assets/img/partner.jpg"})`}}>
                    <div className="text-white h-full flex flex-col justify-center mx-7 sm:px-10 lg:px-14 xl:px-24">
                        <div className="h-screen flex flex-col justify-end">
                            <h1 className="text-3xl font-semibold mb-2 sm:text-5xl md:mb-3 lg:text-6xl">Become a Mindsquare Partner</h1>
                            <p className="text-lg opacity-95 mt-2 sm:text-xl md:mt-3">Our partnerships enable us to help more clients create happier customers</p>
                        </div>
                        <div className="h-full flex items-end">
                            {/* <BouncingArrow /> */}
                            <LoadingCircle />
                        </div>
                    </div>
                </div>
                
                <div className="mx-7 my-14 sm:p-10 md:px-20 lg:px-28">
                    <h1 className="text-3xl font-semibold mb-2">Become a Mindsquare Partner</h1>
                    
                    <AccordionItem question="Why Partnerships Matter" answer="<p>Our partnerships allow us to help more clients in more ways — by making it easier for our clients to adopt our technology. They also support our ability to create new products and services to benefit our clients’ businesses.</p>"/>
                    
                    <AccordionItem question="Who We Partner With" answer="<div>
                        <p>We have partnerships with over 60 leading companies across the entire contact center value chain. Our partnerships include:</p>
                        <p>●	Telephony vendors</p>
                        <p>●	Channel partners</p>
                        <p>●	Business process outsources</p>
                        <p>●	Consultants</p>
                        <p>●	Data companies</p>
                        <p>●	Hosted and managed service providers</p>
                        <p>●	Value-added resellers</p>
                    </div>"/>

                    
                    <AccordionItem question="How We Collaborate With Partners" answer="<div>
                            <p>We collaborate with our partners in three main areas:</p>
                            <strong>Technological</strong>
                            <p>Seamless integration with leading global telephony systems, to simplify deployment and avoid disruption to our clients’ businesses.</p>
                            <strong>Commercial</strong>
                            <p>Addressing our clients’ business needs and identifying clients who could benefit from our joint value proposition.</p>
                            <strong>Strategic</strong>
                            <p>Developing and delivering new products and services.</p>
                        </div>"/>
                </div>
                
                <div>
                    <div className="flex h-[440px] w-screen bg-cover bg-center lg:bg-fill 2xl:h-[462.5px]" style={{backgroundImage: `url(${"https://mindsqaurestorage.blob.core.windows.net/website/assets/img/telephone.jpg"})`}}>
                        <h1 className="self-start text-white mx-7 mt-4 text-5xl italic sm:self-center md:mx-12">Telephony</h1>
                    </div>
                    <div className="flex h-[440px] w-screen bg-cover bg-center lg:bg-fill 2xl:h-[462.5px]" style={{backgroundImage: `url(${"https://mindsqaurestorage.blob.core.windows.net/website/assets/img/channel.jpg"})`}}>
                        <h1 className="self-start text-white mx-7 mt-4 text-5xl italic sm:self-center md:mx-12">Channel</h1>
                    </div>
                    <div className="flex h-[440px] w-screen bg-cover bg-center lg:bg-fill 2xl:h-[462.5px]" style={{backgroundImage: `url(${"https://mindsqaurestorage.blob.core.windows.net/website/assets/img/bpo.jpg"})`}}>
                        <h1 className="self-start text-white mx-7 mt-4 text-5xl italic sm:self-center md:mx-12">BPO</h1>
                    </div>
                </div>
            </main>

            <footer>
                <Footer dark={true}/>
            </footer>
        </>
    )
}